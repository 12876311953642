import { Box, Flex, IconButton, Link, Table, Tag, TagLabel, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE } from 'constants/common';
import { toast } from 'components/Toast';
import { formatCurrencyToVND, formatDate, formatDateVN } from 'utils/helpers';
import { useUserState } from 'context/UserContext';
import { useDeleteServiceMutation, useRESendRequestZaloMutation, useSendRequestZaloMutation } from 'services/service';
import { renderLabelColor } from 'utils/styles';
import { FaRegCopy } from 'react-icons/fa';
import { BsFillSendFill } from 'react-icons/bs';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';

const SizeTable = ({ categoryData, handleUpdateCategory, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const sendRequestZaloMutation = useSendRequestZaloMutation();
  const reSendRequestZaloMutation = useRESendRequestZaloMutation();
  const deleteServiceMutation = useDeleteServiceMutation();

  const handleRowClick = (ticket, type) => {
    handleUpdateCategory(ticket, type);
  };
  const { userInfo } = useUserState();

  const handleDeleteSize = async category => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa dịch vụ này không?');
    if (!confirmDelete) {
      return;
    }
    deleteServiceMutation.mutate(
      { id: category?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa dịch vụ thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa dịch vụ không thành công');
          refetch?.();
        },
      }
    );
  };

  const handleReSendRequestZalo = async service => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn gửi đề nghị để lấy lại mã zalo này không?');
    if (!confirmDelete) {
      return;
    }
    reSendRequestZaloMutation.mutate(
      { phoneServiceId: service?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Gửi đề nghị để lấy lại mã zalo thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      // columnHelper.accessor('thumbnails', {
      //   header: 'Ảnh',
      //   cell: info =>
      //     info.getValue() ? (
      //       <Image src={ROOT_API + '/' + info.getValue()} w="50px" h="50px" objectFit="contain" />
      //     ) : (
      //       <Image src="gibbresh.png" fallbackSrc="https://placehold.co/50?text=image&font=roboto" />
      //     ),
      // }),
      columnHelper.accessor('phoneNumber', {
        header: 'Số điện thoại',
        cell: info =>
          info.getValue() ? (
            <Flex alignItems={'center'} gap={2}>
              <Text>{info.getValue()}</Text>
              <FaRegCopy
                cursor={'pointer'}
                onClick={() => {
                  navigator?.clipboard?.writeText(info.getValue());
                  toast.showMessageSuccess('Đã copy SĐT');
                }}
              />
            </Flex>
          ) : (
            '--'
          ),
      }),
      columnHelper.accessor('service', {
        header: 'Dịch vụ',
        cell: info => info.getValue()?.title || '--',
      }),
      columnHelper.accessor('price', {
        header: 'Xu',
        cell: info => formatCurrencyToVND(info.getValue()),
      }),
      columnHelper.accessor('code', {
        header: 'Mã',
        cell: info =>
          info.getValue() ? (
            <Flex alignItems={'center'} gap={2}>
              <Text>{info.getValue()}</Text>
              <FaRegCopy
                cursor={'pointer'}
                onClick={() => {
                  navigator?.clipboard?.writeText(info.getValue());
                  toast.showMessageSuccess('Đã copy mã');
                }}
              />
            </Flex>
          ) : (
            '--'
          ),
      }),
      // columnHelper.accessor('content', {
      //   header: 'Nội dung',
      //   cell: info => info.getValue() || '--',
      // }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => (
          <Tag colorScheme={renderLabelColor(info.getValue())}>
            <TagLabel>{info.getValue()}</TagLabel>
          </Tag>
        ),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDateVN(info.row.original.createdAt, FORMAT_DATE)}</Text>,
      }),
      columnHelper.accessor('action', {
        header: 'Nhận OTP lần 2',
        cell: info => {
          const { original } = info.row;
          const canSendSms = original.isReceivedCode; // Kiểm tra điều kiện success
          return (
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_PRODUCT_CATEGORY'}>
              {canSendSms && ( // Kiểm tra và hiển thị nút nếu success là true
                <Flex
                  alignItems="center"
                  gap={2}
                  cursor="pointer"
                  onClick={() => {
                    handleReSendRequestZalo(original);
                  }}
                >
                  <Text>Gửi sms</Text>
                  <BsFillSendFill size={16} />
                </Flex>
              )}
            </AuthorizationComponentWrapper>
          );
        },
      }),
    ],
    [categoryData]
  );

  const table = useReactTable({
    data: categoryData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px" isTruncated>
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={7}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
